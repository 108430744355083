import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'numeroEnK',
})
export class NumeroEnKPipe implements PipeTransform {
  transform(num: number | null | undefined): string {
    num = num ?? 0
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)} K`;
    }
    return num + ' ';
  }
}
