import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ISpecialties, ISubSpecialty } from 'src/app/shared/model/userProfession.interfaces';
import { SpecialityService } from 'src/app/shared/services/speciality.service';
import { EditSubSpecialtyComponent } from '../edit-sub-specialty/edit-sub-specialty.component';
import { Sweetalert2Service } from 'src/app/shared/services/sweetalert2.service';

@Component({
  selector: 'app-edit-profecionity',
  templateUrl: './edit-profecionity.component.html',
  styleUrls: ['./edit-profecionity.component.scss']
})
export class EditProfecionityComponent implements OnInit {
  public displayedColumns = ['name', 'terms', 'actions'];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  specialty: ISpecialties;
  dataSource: []
  public form = this.formBuilder.group({
    name: ['', Validators.required],
  });
  id: string
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly specialityService: SpecialityService,
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    private alertService: Sweetalert2Service
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('uuid');

    this.specialityService.get(this.id).subscribe({
      next: (data) => {
        this.specialty = data?.data;
        this.form.setValue({ name: this.specialty.name })
        this.getSubSpecialties(this.specialty._id);
      },
      error: (data) => console.log(data)
    })
  }

  onSubmit() {
    const values = this.form.value
    const handleNext = (data: any) => {
      this.snackBar.open(data?.message, '', { 'duration': 5000 })
      this.router.navigate(['/specialty'])
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.update(this.id, values).subscribe({
      next: handleNext,
      error: handleError,
    })

  }

  getSubSpecialties(id: string) {
    this.specialityService.getServiceBySpeciality(id).subscribe({
      next: data => { this.dataSource = data?.data },
      error: () => alert('Ha ocurrido un error')
    })
  }

  onAddSubSpecialty() {
    const dialogRef = this.dialog.open(EditSubSpecialtyComponent, {
      width: '40%',
      data: { specialty: this.specialty._id },
    });

    dialogRef.afterClosed().subscribe(subSpecialty => {
      if(!!subSpecialty) {
        this.addSubSpecialty(subSpecialty)
      }
    });
  }

  onEditSubSpecialty(subSpecialty: ISubSpecialty) {
    const dialogRef = this.dialog.open(EditSubSpecialtyComponent, {
      width: '40%',
      data: subSpecialty,
    });

    dialogRef.afterClosed().subscribe(subSpecialty => {
      if(!!subSpecialty) {
        this.updateSubSpecialty(subSpecialty)
      }
    });
  }

  onDeleteSubSpecialty(subSpecialty: ISubSpecialty) {
    this.alertService.fireSweetConfirm(
      '¿Desea eliminar esta Sub-especialidad?',
      `${subSpecialty.name}`,
      'warning').then(response=>{
        if(response.isConfirmed) {
          this.deleteSubSpecialty(subSpecialty);
        }
      })
  }

  addSubSpecialty(subSpecialty) {
    const handleNext = (data: any) => {
      this.getSubSpecialties(this.specialty._id)
      this.alertService.fireSweetToastAlert('top-end','success','Sub-especialidad añadida')
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.addSubSpecialty(subSpecialty).subscribe({
      next: handleNext,
      error: handleError,
    })

  }

  updateSubSpecialty(subSpecialty) {
    const handleNext = () => {
      this.getSubSpecialties(this.specialty._id)
      this.alertService.fireSweetToastAlert('top-end','success','Sub-especialidad actualizada')
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.updateSubSpecialty(subSpecialty).subscribe({
      next: handleNext,
      error: handleError,
    })

  }

  deleteSubSpecialty(subSpecialty: ISubSpecialty) {
    const handleNext = () => {
      this.getSubSpecialties(subSpecialty.specialty)
      this.alertService.fireSweetToastAlert('top-end','success','Sub-especialidad eliminada')
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.deleteSubSpecialty(subSpecialty._id).subscribe({
      next: handleNext,
      error: handleError,
    })

  }
}
