import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment'
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class Sweetalert2Service {
  private authS = inject(AuthService)

  fireSweetError(HttpError: HttpErrorResponse){
    const { error } = HttpError
    if (!environment.production) console.log(error)
    if (Array.isArray(error) && error.length > 0) {
      const { msg, message} = error[0] as any
      if(msg) {
        if(msg === 'token missing or invalid'){
          //return this.authS.logout() implemenetar este metodo
          return this.fireSweetOkButton('Error!', JSON.stringify(HttpError),'error')
        }
        return this.fireSweetOkButton('Error!', msg ,'error')
      }
      if(message) return this.fireSweetOkButton('Error!', message ,'error')
    }
    if (error?.msg)    return this.fireSweetOkButton('Error!',error.msg    ,'error')
    if (error?.message) return this.fireSweetOkButton('Error!',error.message,'error')
    if (HttpError?.message) return this.fireSweetOkButton('Error!',HttpError.message,'error')
    return this.fireSweetOkButton('Error!', JSON.stringify(HttpError),'error')
  }

  fireSweetNotification(
    position: 'top' | 'top-start' | 'top-end' | 'center' | 'center-start' | 'center-end' | 'bottom' | 'bottom-start' | 'bottom-end',
    icon: 'success' | 'error' | 'warning' | 'info' | 'question',
    title: string,
    showConfirmButton: boolean =  false,
    timer: number = 1500
  ){
      Swal.fire({position,icon,title,showConfirmButton,timer})
  }

  fireSweetOkButton(title: string, text: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question', confirmButtonText = 'OK'){
    Swal.fire({title, text, icon, confirmButtonText})
  }

  fireSweetConfirm(title: string, text: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question', confirmButtonText = 'Aceptar', cancelButtonText = 'Cancelar'){
    return Swal.fire({title, text, icon, confirmButtonText, showCancelButton: true, cancelButtonText})
  }

  fireSweetHtmlOkButton(title: string, html:string, icon: 'success' | 'error' | 'warning' | 'info' | 'question', confirmButtonText = 'OK'){
    Swal.fire({title, html, icon, confirmButtonText})
  }
  fireSweetToastAlert(
    position: 'top' | 'top-start' | 'top-end' | 'center' | 'center-start' | 'center-end' | 'bottom' | 'bottom-start' | 'bottom-end',
    icon: 'success' | 'error' | 'warning' | 'info' | 'question',
    title: string,
    showConfirmButton: boolean =  false,
    timer: number = 3000,
    timerProgressBar: boolean =  true,
  ){
    const Toast = Swal.mixin({toast: true,position,showConfirmButton,timer,timerProgressBar, didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({ icon, title })
  }

}
