import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http'
import { tap, Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
import { IUser } from '../model/user.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public url = environment.urlApi+'/';
  public declare user : IUser
  public firstlogin = false;

  constructor(private http: HttpClient) {}

  get token():string{
    return localStorage.getItem('token') || '';
  }

  get header(){
    return { headers: { 'authorization': `Bearer ${this.token}` }}
  }

  get(endpoint: any):Observable<any> {
    return this.http.get(this.url + endpoint, this.header).pipe(
      tap(resp => this.log(resp)));
  }

  getAuth(endpoint: any):Observable<any> {
    return this.http.get(this.url + endpoint, this.header).pipe(
      tap(resp => this.log(resp)));
  }

  getAuthFileBlob(endpoint: any): Observable<Blob> {
    return this.http.get(this.url + endpoint, { headers: { 'authorization': `Bearer ${this.token}` }, responseType: 'blob'}).pipe(
      tap(resp => this.log(resp)));
  }

  post(endpoint: string, body: any):Observable<any> {
    return this.http.post(this.url + endpoint, body).pipe(
      tap(resp => this.log(resp)));
  }

  postAuth(endpoint: string, body: any):Observable<any> {
    return this.http.post(this.url + endpoint, body,this.header).pipe(
      tap(resp => this.log(resp)));
  }

  patch(endpoint: string, body: any):Observable<any> {
    return this.http.patch(this.url + endpoint, body).pipe(
      tap(resp => this.log(resp)));
  }

  patchAuth(endpoint: string, body: any):Observable<any> {
    return this.http.patch(this.url + endpoint, body,this.header).pipe(
      tap(resp => this.log(resp)));
  }

  deleteAuth(endpoint: String):Observable<any>{
    return this.http.delete(this.url + endpoint,this.header).pipe(
      tap(resp => this.log(resp)));
  }

  private toJson(res:any) {
    return res.json();
  }

  public log(res: any) {
    if (!environment.production) console.log(res)
  }
}