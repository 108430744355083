import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-new-admins',
  templateUrl: './new-admins.component.html',
  styleUrls: ['./new-admins.component.scss']
})
export class NewAdminsComponent implements OnInit {
  public adminForm = this.formBuilder.group({
    'firstname': ['', Validators.required],
    'lastname': ['', Validators.required],
    'email': ['', Validators.compose([Validators.required, emailValidator])],
    'password': ['', Validators.required],
    'role': ['ADMIN', Validators.required]
  });

  public roles = [
    { id: 1, name: 'ADMIN' },
    { id: 2, name: 'ROLE_SUPER_ADMIN' }
  ];

  constructor(private formBuilder: UntypedFormBuilder, private admServ: AdminService, private snackBar: MatSnackBar,) { }

  ngOnInit() {

  }

  public onSubmit(values: any): void {
    console.log('values', values);
    if (this.adminForm.valid) {
      this.admServ.create(values).subscribe({
        next: (response) => {
          console.log('response', response);
          this.snackBar.open('Anuncio guardado correctamente.', '', {'duration': 5000});
          this.adminForm.reset();
        },
        error: (error) => {
          console.log('error', error);
        }
      });
    }
  }
}

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}
