import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ISubSpecialty } from '../model/userProfession.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SpecialityService {

  private base = 'specialties/';

  constructor(
    private apiService: HttpService,
    private api: ApiService
  ) { }

  create(admin: object) {
    return this.apiService.request(HttpService.METHOD_POST, '/specialties', admin);
  }

  list() {
    return this.apiService.request(HttpService.METHOD_GET, '/specialties', null);
  }

  get(id: string) {
    return this.apiService.request(HttpService.METHOD_GET, '/specialties/' + id, null);
  }

  update(id: string, data: any) {
    return this.apiService.request(HttpService.METHOD_PUT, '/specialties/' + id, data);
  }
  delete(id: string) {
    return this.apiService.request(HttpService.METHOD_DELETE, '/specialties/' + id, null);
  }

  getSubEspecialitys(nameEsp :string): Observable<any> {
    return this.api.getAuth(`${this.base}SubEspecialitys/${nameEsp}`);
  }

  getSubEspcialByIdUser(user:string,body?: { profe:string }): Observable<any> {
    return this.api.patchAuth(`${this.base}getSubEspcialByIdUser/${user}`, body);
  }

  getServiceBySpeciality(id: string) {
    return this.apiService.request(HttpService.METHOD_GET, '/services/' + id, null);
  }

  addSubSpecialty(subSpecilaty: ISubSpecialty) {
    return this.apiService.request(HttpService.METHOD_POST, '/services', subSpecilaty);
  }

  updateSubSpecialty(subSpecilaty: ISubSpecialty) {
    return this.apiService.request(HttpService.METHOD_PATCH, '/services/' + subSpecilaty._id, subSpecilaty);
  }

  deleteSubSpecialty(id: string) {
    return this.apiService.request(HttpService.METHOD_DELETE, '/services/' + id, null);
  }
}
