import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {
  private base = 'Uploads/';
  constructor(private api: ApiService) { }

  updateFotoUser(file:File, id:string) {
    const formData = new FormData()
    formData.append('imagen', file)
    return this.api.postAuth(this.base + 'users/' + id,formData)
  }

  updateUserFile(): Observable<any>{
    return this.api.getAuth(`${this.base}updateUserFile`);
  }
  
  downloadUserFile(): Observable<Blob>{
    return this.api.getAuthFileBlob(`${this.base}downloadUserFile`);
  }

}
