import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SpecialityService } from 'src/app/shared/services/speciality.service';
@Component({
  selector: 'app-new-profecionity',
  templateUrl: './new-profecionity.component.html',
  styleUrls: ['./new-profecionity.component.scss'],
})
export class NewProfecionityComponent implements OnInit {



  public form = this.formBuilder.group({
    name: ['', Validators.required],
  });
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly jwtHelper: JwtHelperService,
    private readonly specialityService: SpecialityService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const values = this.form.value
    const handleNext = (data: any) => {
      this.snackBar.open(data?.message, '', { 'duration': 5000 })
      this.router.navigate(['/specialty'])
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.create(values).subscribe({
      next: handleNext,
      error: handleError,
    })

  }
}
