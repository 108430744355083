import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import updateUserBtn from 'src/app/components/dowload-users-btn/dowload-users-btn.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { AppRoutingModule } from './app.routing';

import { AppSettings } from './app.settings';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';

import { TopInfoContentComponent } from './theme/components/top-info-content/top-info-content.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { NewAdminsComponent } from './pages/new-admins/new-admins.component';
import { LogsComponent } from './pages/logs/logs.component';
import { NewUsersComponent } from './pages/new-users/new-users.component';
import { TablesService } from './pages/tables/tables.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UsersValidateComponent } from './pages/users-validate/users-validate.component';
import { AdvertisementsComponent } from './pages/advertisements/advertisements.component';
import { ProfecionityComponent } from './pages/profecionity/profecionity.component';
import { NewProfecionityComponent } from './pages/new-profecionity/new-profecionity.component';
import { EditProfecionityComponent } from './pages/edit-profecionity/edit-profecionity.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import FormEspecialidadesComponent from './components/specialities/form-especialidades/form-especialidades.component';
import { ChipInputSubEspComponent } from './components/specialities/chipInputSubEsp/chip-input.component';
import FormContactComponent from './components/form-contact/form-contact.component';
import { EditSubSpecialtyComponent } from './pages/edit-sub-specialty/edit-sub-specialty.component';
import { NewUserComponent } from './pages/new-user/new-user.component';


@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        SharedModule,
        PipesModule,
        AppRoutingModule,
        AngularEditorModule,
        updateUserBtn
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        BlankComponent,
        SearchComponent,
        NotFoundComponent,
        ErrorComponent,
        TopInfoContentComponent,
        SidenavComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        FlagsMenuComponent,
        FullScreenComponent,
        ApplicationsComponent,
        MessagesComponent,
        UserMenuComponent,
        FavoritesComponent,
        AdminsComponent,
        NewAdminsComponent,
        LogsComponent,
        NewUsersComponent,
        UsersValidateComponent,
        AdvertisementsComponent,
        ProfecionityComponent,
        NewProfecionityComponent,
        EditProfecionityComponent,
        NewUserComponent,
        EditUserComponent,
        DialogConfirmationComponent,
        FormEspecialidadesComponent,
        ChipInputSubEspComponent,
        FormContactComponent,
        EditSubSpecialtyComponent
    ],
    providers: [
        AppSettings,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        TablesService,
        AuthGuardService,
        AuthService,
        JwtHelperService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
