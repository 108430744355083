import { Component, OnInit } from '@angular/core';
import { FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { IUser } from 'src/app/shared/model/user.interfaces';
import { Sweetalert2Service } from 'src/app/shared/services/sweetalert2.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  validatedChangeSubscrptions$: Subscription;
  loading: boolean = false
  user: Partial<IUser>;
  isProfesionalRole = true;
  roles = [
    {
      viewValue: 'Usuario',
      value: 'BASIC_USER'
    },
    {
      viewValue: 'Profesional',
      value: 'PROFECIONAL'
    }
  ];
  profesions = []
  tagsPro = [];
  public form = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: [''],
    email: ['', Validators.required],
    validated: [false],
    //showCellphone: [false],
    blocked: [false],
    password: [''],
    role: ['', Validators.required],
    //Contact fields
    address:      [null, [Validators.minLength(10), Validators.maxLength(150)]],
    telephone:    [null, [Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
    city:         [null, [Validators.maxLength(100)]],
    postalCode:   [null, [Validators.minLength(5),Validators.maxLength(5)]],
    cellphone:    [null, [Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
    web:          [null, [Validators.maxLength(200), Validators.pattern('^(http:\/\/|https:\/\/).+$')]],
    linkedin:     [null, [Validators.maxLength(200), Validators.pattern('^(http:\/\/|https:\/\/).+$')]],
    showCellphone:[null],
    //Profesion&Specialties fields
    tagsPro: [[]],
    allSpecialties: [[]]
  });
  id: string
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly userService: UserService,
    private route: ActivatedRoute,
    public router: Router,
    private alertService: Sweetalert2Service
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('uuid');
    this.userService.get(this.id).subscribe({
      next: (data) => {
        //console.log({ data });
        this.user = data?.data;
        this.form.patchValue(this.user as any)
        //console.log('Control tagsPro::::', this.form.get('tagsPro').value)
        this.form.get('password').setValue('')
        this.profesions = data?.data?.professions;
        this.tagsPro = data?.data?.tagsPro || [];
        this.onValidatedChangeSubscribe();
        this.isProfesionalRole = ['PROFECIONAL', 'ADMIN'].includes(this.user.role);
        this.roleValueChangeSubscribe()
      },
      error: (data) => console.log(data)
    })
  }

  ngOnDestroy() {
    this.validatedChangeSubscrptions$.unsubscribe();
  }

  onSave() {
    const values = this.form.value

    this.loading = true;

    if (!values.password) {
      delete values.password
    };

    const handleNext = (data: any) => {
      //console.log('RESULT:::', data)
      if(!!data?.data?.user?.tagsPro) {
        this.tagsPro = data.data.user.tagsPro;
        if(this.tagsPro.length === 0) {
          this.user.numberOfSpecialties = 0;
        }
      }

      this.alertService.fireSweetToastAlert('top-end','success','Información actualizada')
    }

    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    if(values.role === 'BASIC_USER') {
      this.form.get('tagsPro').setValue([]);
      this.form.get('allSpecialties').setValue([]);
      values.tagsPro = [];
      values.allSpecialties = [];
    }

    this.userService.updateUser(this.id, values).subscribe({
      next: handleNext,
      error: handleError,
    }).add(() => {
      this.loading = false;
    })

  }

  onValidatedChangeSubscribe() {
    this.validatedChangeSubscrptions$ = this.form.get('validated')
      .valueChanges
      .pipe(filter(value=>!!value))
      .subscribe(()=>{
        if(!this.hasSpecialties) {
          this.alertService.fireSweetOkButton(
            'Revisar especialidades',
            'Debes agregar mínimo una especialidad antes de validar al usuario',
            'warning',
            'Lo revisaré'
          )
          setTimeout(() => {
            this.form.get('validated').setValue(false);
          }, 0);
        }
      })
  }

  getFormValidationErrors() {

    const result = [];
    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });

    return result;
  }

  get hasSpecialties (): boolean {
    const profArray = this.form.get('allSpecialties').value || [] as Array<any>
    const numberOfSpecialties = profArray.reduce((acc, item)=>acc = acc + item.specialties.length, 0);
    //console.log('numberOfSpecialties:::', numberOfSpecialties)
    return numberOfSpecialties > 0
  }

  errorMsg(err) {
    let msg = ''
    switch(err.control) {
      case 'firstname': msg = 'El campo nombre es obligatorio.'; break;
      case 'email': msg = 'El campo email es obligatorio.'; break;
      case 'address':
        msg = err.error == 'required'
          ? 'El campo dirección es obligatorio.'
          : 'El campo dirección debe tener entre 10 y 150 caracteres';
        break;
      case 'city':
        msg = err.error == 'required'
          ? 'El campo ciudad es obligatorio.'
          : 'El campo ciudad no debe tener mas de 100 caracteres';
        break;
      case 'cellphone':
        msg = err.error == 'required'
          ? 'El campo teléfono es obligatorio.'
          : 'Formato de teléfono incorrecto';
        break;
      case 'tagsPro': msg = 'Debe seleccionar al menos una profesión.'; break;
      case 'allSpecialties': msg = 'Debe seleccionar al menos una especialidad.'; break;
    }

    return msg;
  }

  roleValueChangeSubscribe() {
    this.form.get('role').valueChanges.subscribe(
      value=>{
        this.isProfesionalRole = ['PROFECIONAL', 'ADMIN'].includes(value);
        ['tagsPro', 'allSpecialties'].forEach(
          field=> {
            this.form.get(field).setValidators(
              this.isProfesionalRole ? [Validators.required] : null
            )
            this.form.get(field).updateValueAndValidity();
        })
      }
    )
  }

}
