import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, Observable, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-form-especialidades',
  templateUrl: './form-especialidades.component.html',
  styleUrls: ['./form-especialidades.component.scss']
})

export default class FormEspecialidadesComponent {
  @ViewChild('chipList') chipList: ElementRef<MatChipList>;
  @ViewChild('profsInputChips') profsInputChips: ElementRef<HTMLInputElement>;
  @Input() form: FormGroup;
  @Input() id: string;
  @Input() hasSpecialties = false;

  selectedProf: string;
  profs: string[] = [];
  profCtrl = new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredProfs: Observable<string[]>;
  allProfesions = ['Abogado', 'Árbitro', 'Despachos Profesionales/Empresas', 'Docente', 'Mediador', 'Notario', 'Perito', 'Procurador']
  deletedTagPro: string = null;

  constructor() {
  }

  ngOnInit() {
    this.inicializar()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['form']?.isFirstChange()) {
      const subs = this.form.get('tagsPro').valueChanges.subscribe(
        value=>{
          if(value?.length > 0) {
            this.selectedProf = this.tagsPro.length > 0 ? this.tagsPro[0] : null;
            this.profs = [...this.tagsPro]
            this.allProfesions = this.allProfesions.filter(
              p=>!this.profs.includes(p)
            )
            this.inicializar()
            subs.unsubscribe();
          }
        }
      )
    }
  }

  updateAllSpecialties(allSpecialties: []){
    this.allSpecialtiesFiled?.setValue(allSpecialties);
    this.allSpecialtiesFiled?.updateValueAndValidity();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLocaleLowerCase();
    this.addAndDelete(value)
    event.chipInput!.clear();
    this.profCtrl.setValue(null);
    this.updateTagsProField();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue.toLocaleLowerCase()
    this.addAndDelete(value)
    this.profsInputChips.nativeElement.value = '';
    this.profCtrl.setValue(null);
  }

  remove(label: string): void {
    const index = this.tagsPro.indexOf(label);
    if (index >= 0) {

      let nextSelection = this.selectedProf;

      if(this.selectedProf == this.tagsPro[index]) {
        nextSelection = index == this.tagsPro.length -1
          ? this.tagsPro[index-1] : this.tagsPro[index+1]
      }

      this.profs.splice(index, 1);
      this.allProfesions.push(label)
      this.allProfesions.sort()
      this.profsInputChips.nativeElement.value = '';
      this.profCtrl.setValue(null);
      this.selectedProf = nextSelection;
      this.updateTagsProField();
      this.deletedTagPro = label;
    }
  }

  inicializar(){
    this.filteredProfs = this.profCtrl.valueChanges.pipe(
      tap(t=>console.log(t)),
      startWith(null),
      map((label: string | null) => (
        label ? this._filter(label) : this.allProfesions.slice())
      ),
    );
  }

  addAndDelete(value:string){
    const [value2] =  this.profs.filter(label => label.toLowerCase().includes(value));
    const [value3] =  this.allProfesions.filter(label => label.toLowerCase().includes(value));
    if (value && !value2 && value3) {
      this.profs.push(value3);
      this.allProfesions = this.allProfesions.filter((elemento) => elemento !== value3);
    }
    this.updateTagsProField();
    this.selectedProf = this.profs[this.profs.length - 1];
  }

  private _filter(value: string): string[] {
    return this.allProfesions.filter(label => label.toLowerCase().includes(value.toLowerCase()));
  }

  updateTagsProField() {
    this.form.get('tagsPro').setValue(this.profs);
    this.form.get('tagsPro').updateValueAndValidity();
  }

  get tagsPro() {
     return this.form.get('tagsPro')?.value || [];
  }

  get allSpecialtiesFiled() { return this.form.get('allSpecialties'); }

}
