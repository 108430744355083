import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISubSpecialty } from 'src/app/shared/model/userProfession.interfaces';

@Component({
  selector: 'app-edit-sub-specialty',
  templateUrl: './edit-sub-specialty.component.html',
  styleUrls: ['./edit-sub-specialty.component.scss']
})
export class EditSubSpecialtyComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditSubSpecialtyComponent>,
    @Inject(MAT_DIALOG_DATA) public subSpecialty: ISubSpecialty
  ) { }

  ngOnInit(): void {

    const { _id, name, terms, specialty } = this.subSpecialty;

    this.form = this.formBuilder.group({
      _id: [_id || null],
      name: [name || null, Validators.required],
      terms: [terms || null, Validators.required],
      specialty: [specialty || null, Validators.required],
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
