import { Injectable } from '@angular/core';
import { UserModel } from '../model/user.model';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private base = 'users/';

  constructor(
    private apiService: HttpService,
    private api: ApiService
  ) { }

  create(user: UserModel) {
    return this.apiService.request(HttpService.METHOD_POST, '/users', user);
  }

  list() {
    return this.apiService.request(HttpService.METHOD_GET, '/users', null);
  }

  get(id: string) {
    return this.apiService.request(HttpService.METHOD_GET, '/users/' + id, null);
  }

  update(id: string, data: any) {
    return this.apiService.request(HttpService.METHOD_PATCH, '/users/updateUser/' + id, data);
  }

  updateFormacionPro(body: any): Observable<any> {
    return this.api.patchAuth(`${this.base}updateFormacionPro`, body);
  }

  updateUser(id:string ,body): Observable<{data:{user,token:string},message:string}> {
    return this.api.patchAuth(`${this.base}updateUser/${id}`, body);
  }
}
