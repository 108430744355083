import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public static readonly METHOD_POST = 'POST';
  public static readonly METHOD_GET = 'GET';
  public static readonly METHOD_PUT = 'PUT';
  public static readonly METHOD_PATCH = 'PATCH';
  public static readonly METHOD_DELETE = 'DELETE';

  private urlApi = environment.urlApi;
  private token = localStorage.getItem('token');

  constructor(private httpClient: HttpClient) { }

  request<T>(method: string, endpoint: string, body: any): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`,
      }
    );
    let options = {
      'body': body,
      'headers': headers
    };

    return this.httpClient.request(method, this.urlApi + endpoint, options);
  }

  get(endpoint: string, body: any): Observable<any>{
    let headers = new HttpHeaders({'Authorization': `Bearer ${this.token}`});
    let options = {'headers': headers, 'body': body};
    return this.httpClient.get(this.urlApi + endpoint, options);
  }

  requestUrlEncode<T>(endpoint: string, body: any): Observable<any> {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/form-data')
    };
    const param = this.JSON_to_URLEncoded(body);
    console.log(param);
    return this.httpClient.post(this.urlApi + endpoint, param, header);
  }

  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element === 'object') {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + '[' + idx + ']' : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + '=' + encodeURIComponent(element));
    }
    return new_list.join('&');
  }

  requestFormData<T>(endpoint: string, formData: FormData): Observable<any>{
    let headers = new HttpHeaders(
      {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/form-data'
      }
    );
    
    return this.httpClient.post(this.urlApi + endpoint, formData, {'headers': headers});
  }
}
