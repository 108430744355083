// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false,
  urlApi: 'http://localhost:3000/api',
  urlImagesAds: 'http://localhost:3000/api/uploads/anuncios/',
  urlImagesNews: 'http://localhost:3000/api/uploads/noticias/',
  urlImagesUsers: 'http://localhost:3000/api/uploads/usuarios/',
  urlImagesPub: 'http://localhost:3000/api/uploads/publicaciones/',
  urlPerfil: 'https://lexadvisor.net/perfil/'
};
*/
export const environment = {
  production: true,
  urlApi: 'https://api.lexadvisor.net/api',
  urlImagesAds: 'https://api.lexadvisor.net/api/uploads/anuncios/',
  urlImagesNews: 'https://api.lexadvisor.net/api/uploads/noticias/',
  urlImagesUsers: 'https://api.lexadvisor.net/api/uploads/usuarios/',
  urlImagesPub: 'https://api.lexadvisor.net/api/uploads/publicaciones/',
  urlPerfil: 'http://lexadvisor.net/perfil/'
};
// export const environment = {
//   production: false,
//   urlApi: 'https://backlexadvisor.danngos.tech/api',
//   urlImagesAds: 'https://backlexadvisor.danngos.tech/api/uploads/anuncios/',
//   urlImagesNews: 'https://backlexadvisor.danngos.tech/api/uploads/noticias/',
//   urlImagesUsers: 'https://backlexadvisor.danngos.tech/api/uploads/usuarios/',
//   urlImagesPub: 'https://backlexadvisor.danngos.tech/api/uploads/publicaciones/',
//   urlPerfil: 'https://lexadvisor.net/perfil/'
// };

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
