import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { TablesService, Element, Admin } from '../tables/tables.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchService } from 'src/app/shared/services/search.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { AdversimentService } from 'src/app/shared/services/adversiment.service';

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.scss']
})
export class AdvertisementsComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['title', 'body', 'location', 'createdAt', 'actions'];
  public dataSource: any;
  public settings: Settings;
  public pag: any;
  constructor(
    public appSettings: AppSettings,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private adversimentService: AdversimentService
  ) {
    this.settings = this.appSettings.settings;
    this.list();
  }

  list() {
    this.searchService.searchAdvertisements(0, 1000000).subscribe(data => {
      console.log('list', data.allAdvertisementsFound);
      this.dataSource = new MatTableDataSource<any>(data.allAdvertisementsFound);
      this.dataSource.paginator = this.paginator;
      this.pag = true;
    });
  }

  block(id: string) {
    console.log('block', id);
    let resp = this.adminService.delete(id);
    resp.subscribe(data => {
      this.snackBar.open('Anuncio actualizado correctamente.', '', { 'duration': 5000 });
    });
    this.list();
  }

  handleDelete(id: string) {
    const handleNext = () => this.list()
    const handleError = (e: any) => this.snackBar.open(e.message);

    this.adversimentService.delete(id).subscribe({
      next: handleNext,
      error: handleError
    })
  }
}
