import { Menu } from './menu.model';

export const verticalMenuItems = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Usuarios', null, null, 'supervisor_account', null, true, 0),
    new Menu (3, 'Administradores', null, null, 'supervisor_account', null, true, 0),
    new Menu (10, 'Noticias', null, null, 'label', null, true, 0),
    new Menu (11, 'Publicidad', null, null, 'data_usage', null, true, 0),
    new Menu (54, 'Anuncios', null, null, 'data_usage', null, true, 0),
    new Menu (55, 'Publicaciones', null, null, 'data_usage', null, true, 0),
    new Menu (56, 'Especialidades', null, null, 'data_usage', null, true, 0),
    /*new Menu (12, 'Formación', null, null, 'open_in_new', null, true, 0),*/
    new Menu (13, 'Forum', '/forum', null, 'chat_bubble', null, false, 0),
    /*new Menu (14, 'Logs', '/ui/snack-bar', null, 'sms', null, false, 0),*/
    new Menu (20, 'Lista de Usuarios', '/users', null, 'view_stream', null, false, 2),
    /*new Menu (21, 'Nuevo Usuario', '/new-users', null, 'add', null, false, 2),*/
    new Menu (22, 'Lista de Admins', '/admins', null, 'view_stream', null, false, 3),
    new Menu (23, 'Nuevo Admin', '/new-admins', null, 'add', null, false, 3),
    new Menu (53, 'Usuarios para validar', '/users-validate', null, 'check', null, false, 3),
    new Menu (24, 'Lista de Noticias', '/news', null, 'view_stream', null, false, 10),
    new Menu (25, 'Nueva Noticia', '/new-news', null, 'add', null, false, 10),
    new Menu (26, 'Lista de Publicidad', '/ads', null, 'view_stream', null, false, 11),
    new Menu (27, 'Nueva Publicación', '/new-publications', null, 'add', null, false, 55),
    new Menu (28, 'Lista de Publicaciones', '/publications', null, 'view_stream', null, false, 55),
    new Menu (28, 'Lista de Anuncios', '/advertisements', null, 'view_stream', null, false, 54),
    new Menu (29, 'Nuev anuncio', '/new-advertisements', null, 'add', null, false, 54),
    new Menu (28, 'Lista de Especialidades', '/specialty', null, 'view_stream', null, false, 56),
    new Menu (30, 'Nueva especialidad', '/new-specialty', null, 'add', null, false, 56),
    /*new Menu (30, 'Otras Paginas', null, null, 'label', null, true, 0),
    new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 30),
    new Menu (51, 'Projects', '/profile/projects', null, 'note', null, false, 30),
    new Menu (52, 'User Info', '/profile/user-info', null, 'perm_contact_calendar', null, false, 30),*/
]

export const horizontalMenuItems = [
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
]
