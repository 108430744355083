import { AuthService } from './../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { IUser } from 'src/app/shared/model/user.interfaces';
import { UserModel } from 'src/app/shared/model/user.model';
import { Sweetalert2Service } from 'src/app/shared/services/sweetalert2.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  validatedChangeSubscrptions$: Subscription;
  loading: boolean = false
  user: Partial<IUser>;
  isProfesionalRole = false;
  hidePassword = [true, true]
  roles = [
    {
      viewValue: 'Usuario',
      value: 'BASIC_USER'
    },
    {
      viewValue: 'Profesional',
      value: 'PROFECIONAL'
    }
  ];
  profesions = []
  tagsPro = [];
  public form = this.formBuilder.group({
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', Validators.required],
    validated: [false],
    //showCellphone: [false],
    blocked: [false],
    password: ['unknow', Validators.required],
    /*confirmPassword: ['', Validators.required],*/
    role: ['', Validators.required],
    //Contact fields
    address:      [null, [Validators.minLength(10), Validators.maxLength(150)]],
    telephone:    [null, [Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
    city:         [null, [Validators.maxLength(100)]],
    postalCode:   [null, [Validators.minLength(5),Validators.maxLength(5)]],
    cellphone:    [null, [Validators.maxLength(15), Validators.pattern("^[0-9]*$")]],
    web:          [null, [Validators.maxLength(200), Validators.pattern('^(http:\/\/|https:\/\/).+$')]],
    linkedin:     [null, [Validators.maxLength(200), Validators.pattern('^(http:\/\/|https:\/\/).+$')]],
    showCellphone:[null],
    //Profesion&Specialties fields
    tagsPro: [[]],
    allSpecialties: [[]]
  });
  id: string
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly userService: UserService,
    public router: Router,
    private alertService: Sweetalert2Service
  ) { }

  ngOnInit(): void {
    this.onValidatedChangeSubscribe();
    this.roleValueChangeSubscribe();
  }

  ngOnDestroy() {
    this.validatedChangeSubscrptions$.unsubscribe();
  }

  onSave() {
    const values = this.form.value

    this.loading = true;

    const handleNext = (resp: any) => {
      if(values.role === 'PROFECIONAL' && values.tagsPro.length > 0 && this.hasSpecialties) {
        const { tagsPro, allSpecialties } = values;
        const { _id } = resp.data.user;

        this.userService.updateUser(_id, { tagsPro, allSpecialties }).subscribe(()=>{
          this.alertService.fireSweetToastAlert('top-end','success','Usuario creado')
          this.router.navigateByUrl('users');
        })
      }
      else {
        this.alertService.fireSweetToastAlert('top-end','success','Usuario creado')
        this.router.navigateByUrl('users');
      }
    }

    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    if(values.role === 'BASIC_USER') {
      this.form.get('tagsPro').setValue([]);
      this.form.get('allSpecialties').setValue([]);
      values.tagsPro = [];
      values.allSpecialties = [];
    }

    this.userService.create(values as UserModel).subscribe({
      next: handleNext,
      error: handleError,
    }).add(() => {
      this.loading = false;
    })

  }

  onValidatedChangeSubscribe() {
    this.validatedChangeSubscrptions$ = this.form.get('validated')
      .valueChanges
      .pipe(filter(value=>!!value))
      .subscribe(()=>{
        if(!this.hasSpecialties) {
          this.alertService.fireSweetOkButton(
            'Revisar especialidades',
            'Debes agregar mínimo una especialidad antes de validar al usuario',
            'warning',
            'Lo revisaré'
          )
          setTimeout(() => {
            this.form.get('validated').setValue(false);
          }, 0);
        }
      })
  }

  getFormValidationErrors() {

    const result = [];
    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });

    return result;
  }

  get hasSpecialties (): boolean {
    const profArray = this.form.get('allSpecialties').value || [] as Array<any>
    const numberOfSpecialties = profArray.reduce((acc, item)=>acc = acc + item.specialties.length, 0);
    //console.log('numberOfSpecialties:::', numberOfSpecialties)
    return numberOfSpecialties > 0
  }

  errorMsg(err) {
    let msg = ''
    switch(err.control) {
      case 'firstname': msg = 'El campo nombre es obligatorio.'; break;
      case 'lastname': msg = 'El campo apellidos es obligatorio.'; break;
      case 'email': msg = 'El campo email es obligatorio.'; break;
      case 'password': msg = 'El campo contraseña es obligatorio.'; break;
      case 'confirmPassword': msg = 'Debe confirmar la contraseña.'; break;
      case 'role': msg = 'El campo "Rol de Usuario" es obligatorio.'; break;
      case 'address':
        msg = err.error == 'required'
          ? 'El campo dirección es obligatorio.'
          : 'El campo dirección debe tener entre 10 y 150 caracteres';
        break;
      case 'city':
        msg = err.error == 'required'
          ? 'El campo ciudad es obligatorio.'
          : 'El campo ciudad no debe tener mas de 100 caracteres';
        break;
      case 'cellphone':
        msg = err.error == 'required'
          ? 'El campo teléfono es obligatorio.'
          : 'Formato de teléfono incorrecto';
        break;
      case 'tagsPro': msg = 'Debe seleccionar al menos una profesión.'; break;
      case 'allSpecialties': msg = 'Debe seleccionar al menos una especialidad.'; break;
    }

    return msg;
  }

  roleValueChangeSubscribe() {
    this.form.get('role').valueChanges.subscribe(
      value=>{
        this.isProfesionalRole = value === 'PROFECIONAL';
        ['tagsPro', 'allSpecialties'].forEach(
          field=> {
            this.form.get(field).setValidators(
              value === 'PROFECIONAL' ? [Validators.required] : null
            )
            this.form.get(field).updateValueAndValidity();
        })
      }
    )
  }
}
