import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
moment.locale('es')

@Pipe({
  name: 'momentDate'
})
export class momentDatePipe implements PipeTransform {

  transform(fecha: string | Date, format: string): string {
    if(typeof fecha === 'string') fecha = new Date(fecha)
    return moment(fecha).format(format)
  }

}