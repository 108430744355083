import { Observable } from 'rxjs';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { SpecialityService } from 'src/app/shared/services/speciality.service';

@Component({
  selector: 'app-chipInputSubEsp',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss']
})
export class ChipInputSubEspComponent implements OnChanges {
  @Input('profe') profe: any = '';
  @Input() deletedTagPro: string = null;
  @Input() id: string;
  @Output() ChangeValue: EventEmitter<any> = new EventEmitter()
  @ViewChild('labelsInputChips') declare labelsInputChips: ElementRef<HTMLInputElement>;

  allSpecialties = [];
  allLabels: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  labelCtrl = new FormControl('');
  declare filteredLabels: Observable<string[]>;
  labels: string[] = [];
  public especialidades = ['Civil','Mercantil','Administrativo','Fiscal y tributario', 'Internacional Privado', 'Internacional Público', 'ADR','Penal','Laboral']
  public especialidadesbackup = ['Civil','Mercantil','Administrativo','Fiscal y tributario', 'Internacional Privado', 'Internacional Público', 'ADR','Penal','Laboral']
  public especialidadesSelected = null
  public savedObjEspecialidad: any = []
  declare especialidadApi : any
  constructor(
    private esp: SpecialityService
  ) {
    this.especialidades.sort()
    this.inicializar()
  }

  inicializar(){
    this.filteredLabels = this.labelCtrl.valueChanges.pipe(
      startWith(null),
      map((label: string | null) => (label ? this._filter(label) : this.allLabels.slice())),
    );
  }

  ngOnInit() {
    this.getAllSpecialties();
  }

  ngOnChanges(changes: SimpleChanges): void{

    if(changes['profe']) {
      this.prepareProfSpecialties()
    }

    if(changes['deletedTagPro']) {
      this.allSpecialties = this.allSpecialties.filter(s=>s.proName != this.deletedTagPro);
      this.emitirValor();
    }
  }

  getAllSpecialties() {
    this.esp.getSubEspcialByIdUser(this.id).subscribe({
      next:({data})=>{
        if(data?.length > 0) {
          this.allSpecialties = data;
          this.prepareProfSpecialties();
          this.emitirValor();
        }
      },
      error:(e)=>{
        console.log(e);
      }
    })
  }

  prepareProfSpecialties() {
    this.savedObjEspecialidad = [];
    this.especialidades = [...this.especialidadesbackup];

    const selectedProf = this.allSpecialties.find(
      p=>p.proName==this.profe
    )

    if(!!selectedProf) {
      const currentSpecialties = selectedProf.specialties.map(item=>item.name)

      selectedProf.specialties.forEach((item:any)=>{
        this.savedObjEspecialidad.push(item)
        this.especialidades = this.especialidadesbackup.filter(
          espItem=>!currentSpecialties.includes(espItem)
        )
      })
    }
    else {
      this.allSpecialties.push({ proName: this.profe, specialties: [] });
    }
  }

  private _filter(value: string): string[] {
    return this.allLabels.filter(label => label.toLowerCase().includes(value.toLowerCase()));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue.toLocaleLowerCase()
    this.addAndDelete(value)
    this.labelsInputChips.nativeElement.value = '';
    this.labelCtrl.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim().toLocaleLowerCase();
    this.addAndDelete(value)
    event.chipInput!.clear();
    this.labelCtrl.setValue(null);
  }

  addAndDelete(value:string){
    const [value2] =  this.labels.filter(label => label.toLowerCase().includes(value));
    const [value3] =  this.allLabels.filter(label => label.toLowerCase().includes(value));
    if (value && !value2 && value3) {
      this.labels.push(value3);
      this.allLabels = this.allLabels.filter((elemento) => elemento !== value3);
    }
  }

  remove(label: string): void {
    const index = this.labels.indexOf(label);
    if (index >= 0) {
      this.labels.splice(index, 1);
      this.allLabels.push(label)
      this.allLabels.sort()
      this.labelsInputChips.nativeElement.value = '';
      this.labelCtrl.setValue(null);
    }
  }

  changeEspSelect({value}:any){
    this.esp.getSubEspecialitys(value).subscribe({
      next: ({data}:any)=> {
        this.allLabels = []
        this.labels = []
        this.especialidadApi = data;
        data.forEach(({name}:any)=>{
          this.allLabels.push(name)
        })
        this.allLabels.sort()
        this.inicializar()
      },
      error: (e:any)=> {
        console.log(e);
      }
    })
  }
  marcarTodo(){
    this.allLabels.forEach((label)=> this.labels.push(label))
    this.allLabels = []
    this.labelsInputChips.nativeElement.value = '';
    this.labelCtrl.setValue(null);
  }
  saveEspecialidad(){
    this.especialidades = this.especialidades.filter((elemento) => elemento !== this.especialidadesSelected)
    const Obj:any = { name : this.especialidadesSelected, subSpecialty: []}
    this.especialidadesSelected = null;
    this.especialidadApi.forEach(({name, _id}:any)=>{
      if(this.labels.includes(name)){
        Obj.subSpecialty.push({name, _id})
      }
    })
    this.savedObjEspecialidad.push(Obj)
    this.allLabels = []
    this.labels = []
    this.labelsInputChips.nativeElement.value = '';
    this.labelCtrl.setValue(null);
    this.emitirValor()
  }

  deleteEsp(name:string){
    this.especialidades.push(name)
    this.especialidades.sort()
    const index = this.savedObjEspecialidad.findIndex((obj:any) => obj.name === name);
    if (index !== -1) {
      this.savedObjEspecialidad.splice(index, 1);
    }
    this.emitirValor()
  }
  emitirValor(){
      const profIndex = this.allSpecialties.findIndex(p=>p.proName==this.profe);

      if(/*this.savedObjEspecialidad.length > 0 && */profIndex >= 0) {
        this.allSpecialties[profIndex].specialties = this.savedObjEspecialidad;
      }

      const emitArray = this.allSpecialties.map(prof=>{
        const { proName, specialties } = prof;
        return {
          proName,
          specialties: specialties.map(esp=>{
            const name = esp.name
            const subSpecialty:any = []
            const subName:string[] = []
            esp.subSpecialty.forEach((obj2:any)=>{
              subSpecialty.push(obj2._id)
              subName.push(obj2.name)
            })
            return {name, subSpecialty, subName}
          })
        }
      })

      this.ChangeValue.emit(emitArray)
  }
}
