import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private apiService: HttpService) { }

  create(admin: object){
    return this.apiService.request(HttpService.METHOD_POST, '/users/createNewAdmin', admin);
  }

  list(){
    return this.apiService.request(HttpService.METHOD_GET, '/admins', null);
  }

  get(id: string){
    return this.apiService.request(HttpService.METHOD_GET, '/admins/'+id, null);
  }

  delete(id: string){
    return this.apiService.request(HttpService.METHOD_DELETE, '/admins/'+id, null);
  }

  meta(){
    return this.apiService.request(HttpService.METHOD_GET, '/meta', null);
  }

  updatePub(id: string, pub: object){
    return this.apiService.request(HttpService.METHOD_PATCH, '/publications/updatePublication/'+id, pub);
  }
}
