import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapString'
})
export class mapStringPipe implements PipeTransform {


  transform(text: string): string {
    let result = WORDS.find(word => word[text]);
    return result[text];
  }

}

export const WORDS = [{'ADMIN': 'Administrador'}, {'PROFECIONAL': 'Profesional'}, {'BASIC_USER': 'Usuario'}];