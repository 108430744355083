import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AdversimentService {

  constructor(private apiService: HttpService) { }

  create(admin: object) {
    return this.apiService.request(HttpService.METHOD_POST, '/users/createNewAdmin', admin);
  }

  list() {
    return this.apiService.request(HttpService.METHOD_GET, '/advertisement', null);
  }

  get(id: string) {
    return this.apiService.request(HttpService.METHOD_GET, '/advertisement/' + id, null);
  }

  delete(id: string) {
    return this.apiService.request(HttpService.METHOD_DELETE, '/advertisement/' + id, null);
  }

}
