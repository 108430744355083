import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { TablesService, Element, Admin } from '../tables/tables.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['name', 'email', 'role'];
  public dataSource: any;
  public settings: Settings;
  public pag: any;
  constructor(
    public appSettings:AppSettings, 
    private tablesService:TablesService,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private searchService: SearchService
  ) {
    this.settings = this.appSettings.settings; 
    this.listAds();
  }

  listAds() {
    this.searchService.searchUsers(0, 1000000, JSON.stringify({"role": "ADMIN"})).subscribe(data => {
      console.log('list', data.users);
      this.dataSource = new MatTableDataSource<any>(data.users);
      this.dataSource.paginator = this.paginator;
      this.pag = true;
    });
  }

  edit(id: string) {
    console.log('editar', id);
  }

  remove(id: string) {
    console.log('eliminar', id);
    let resp = this.adminService.delete(id);
    resp.subscribe(data => {
      this.snackBar.open('Anuncio eliminado correctamente.', '', {'duration': 5000});
    });
    this.listAds();
  }

  changePassword(id: string) {
    console.log('changePassword', id);
  }

}
