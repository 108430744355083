import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SpecialityService } from 'src/app/shared/services/speciality.service';

@Component({
  selector: 'app-edit-profecionity',
  templateUrl: './edit-profecionity.component.html',
  styleUrls: ['./edit-profecionity.component.scss']
})
export class EditProfecionityComponent implements OnInit {

  profesionity: any
  public form = this.formBuilder.group({
    name: ['', Validators.required],
  });
  id: string
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly specialityService: SpecialityService,
    private route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('uuid');

    this.specialityService.get(this.id).subscribe({
      next: (data) => {
        this.form.setValue({ name: data?.data?.name })

      },
      error: (data) => console.log(data)
    })
  }

  onSubmit() {
    const values = this.form.value
    const handleNext = (data: any) => {
      this.snackBar.open(data?.message, '', { 'duration': 5000 })
      this.router.navigate(['/specialty'])
    }
    const handleError = (data: any) => { this.snackBar.open(data?.message, '', { 'duration': 5000 }) }

    this.specialityService.update(this.id, values).subscribe({
      next: handleNext,
      error: handleError,
    })

  }

}
