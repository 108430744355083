import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchService } from 'src/app/shared/services/search.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { Sweetalert2Service } from 'src/app/shared/services/sweetalert2.service';

@Component({
  selector: 'app-users-validate',
  templateUrl: './users-validate.component.html',
  styleUrls: ['./users-validate.component.scss']
})
export class UsersValidateComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['PIC','PRO', 'ESP', 'name', 'email', 'role', 'actions'];
  public dataSource: any;
  public pag: any;
  orderUserNum:number = 2
  order?: { createdAt : number }
  constructor(
    private snackBar: MatSnackBar,
    private searchService: SearchService,
    private userSer: UserService,
    private alertService: Sweetalert2Service
  ) {
    this.list();
  }

  list() {
    this.searchService.searchToValidate(0, 1000000, { order: this.order }).subscribe(data => {
      console.log('list', data.users);
      this.dataSource = new MatTableDataSource<any>(data.users);
      this.dataSource.paginator = this.paginator;
      this.pag = true;
    });
  }

  validar(user) {
    const id = user._id;
    if(user.numberOfSpecialties === 0) {
      this.alertService.fireSweetOkButton(
        'Revisar especialidades',
        'Debes agregar mínimo una especialidad antes de validar al usuario',
        'warning',
        'Lo revisaré'
      )
      return;
    }

    this.userSer.update(id, {validated: true}).subscribe(
      data => {
        this.snackBar.open('Usuario validado correctamente.', '', {'duration': 5000});
        this.list();
    });
  }

  goToPerfil(id: string) {
    window.open(environment.urlPerfil+id, '_blank');
  }

  ordenUser(num:number){
    if(num === 3) {
      this.orderUserNum = 1 // MENOS RECIENTES
      this.order = { createdAt: 1}
    }
    if(num === 2) {
      this.orderUserNum = 3 // MAS RECIENTES
      this.order = { createdAt: -1}
    }
    if(num === 1) {
      this.orderUserNum = 2 // ELIMINAR EL FILTRO
      this.order = undefined
    }
    this.list();
  }
}
