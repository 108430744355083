import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Advertisements } from '../model/ads.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private apiService: HttpService) { }

  searchUsers(start: number = 0, limit: number = 10, data: any = JSON.stringify({"firstname": "a", "lastname":  "Tovar Barragan", "email": "ad", "reset": true})){
    let dataParse = JSON.parse(data);
    return this.apiService.request(HttpService.METHOD_POST, '/search/users?start='+start+'&limit='+limit, dataParse);
  }

  searchToValidate(start: number = 0, limit: number = 10, body:any)
  {
    return this.apiService.request(HttpService.METHOD_POST, '/search/searchUsersToValidate?start='+start+'&limit='+limit, body);
  }

  findByNameOrEmail(query: string){
    return this.apiService.request(HttpService.METHOD_POST, '/search/userByNameOrEmail/'+query, null);
  }

  searchAllBody(start: number = 0, limit: number = 10, data: any = JSON.stringify({"reset": true})) {
    return this.apiService.request(HttpService.METHOD_POST, '/publications/searchAllBody?start='+start+'&limit='+limit, data);
  }

  searchAdvertisements(start: number = 0, limit: number = 10, data: any = JSON.stringify({"reset": true})): Observable<Advertisements> {
    return this.apiService.request(HttpService.METHOD_POST, '/advertisement/searchAdvertisements?start='+start+'&limit='+limit, data);
  }
}