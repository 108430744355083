import { Injectable } from '@angular/core';

export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  actions: string
}

const data: Element[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', actions: 'Activar' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', actions: 'Activar' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', actions: 'Activar' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', actions: 'Activar' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B', actions: 'Activar' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', actions: 'Activar' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', actions: 'Activar' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', actions: 'Activar' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', actions: 'Activar' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', actions: 'Activar' },
  { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na', actions: 'Activar' },
  { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg', actions: 'Activar' },
  { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al', actions: 'Activar' },
  { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si', actions: 'Activar' },
  { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P', actions: 'Activar' },
  { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S', actions: 'Activar' },
  { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl', actions: 'Activar' },
  { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar', actions: 'Activar' },
  { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K', actions: 'Activar' },
  { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca', actions: 'Activar' },
];

const admins: Admin[] = [
  { _id: '1', username: 'hyd', email: 'Hydrogen', role: 'ROLE_USER' },
  { _id: '2', username: 'helium', email: 'Helium', role: 'ROLE_USER' },
  { _id: '3', username: 'lithium', email: 'Lithium', role: 'ROLE_USER' },
  { _id: '4', username: 'beryllium', email: 'Beryllium', role: 'ROLE_USER' },
  { _id: '5', username: 'boron', email: 'Boron', role: 'ROLE_USER' },
  { _id: '6', username: 'Carbon', email: 'carbon', role: 'ROLE_USER' },
  { _id: '7', username: 'Nitrogen', email: 'nitrogen', role: 'ROLE_USER' },
  { _id: '8', username: 'Oxygen', email: 'oxygen', role: 'ROLE_USER' },
  { _id: '9', username: 'Fluorine', email: 'fluorine', role: 'ROLE_USER' },
  { _id: '10', username: 'Neon', email: 'neon@neon.com', role: 'ROLE_USER' },
  { _id: '11', username: 'Sodium', email: 'sodium@sodium.com', role: 'ROLE_USER' },
  { _id: '12', username: 'Magnesium', email: 'sodium@sodium.com', role: 'ROLE_USER' },
  { _id: '13', username: 'Aluminum', email: 'sodium@sodium.com', role: 'ROLE_USER' },
  { _id: '14', username: 'Silicon', email: 'sodium@sodium.com', role: 'ROLE_USER' },
];

const formations: Formation[] = [
  { _id: '1', title: 'hyd', body: 'Hydrogen', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '2', title: 'helium', body: 'Helium', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '3', title: 'lithium', body: 'Lithium', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '4', title: 'beryllium', body: 'Beryllium', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '5', title: 'boron', body: 'Boron', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '6', title: 'Carbon', body: 'carbon', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '7', title: 'Nitrogen', body: 'nitrogen', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '8', title: 'Oxygen', body: 'oxygen', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '9', title: 'Fluorine', body: 'fluorine', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '10', title: 'Neon', body: 'neon@neon.com', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '11', title: 'Sodium', body: 'sodium@sodium.com', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '12', title: 'Magnesium', body: 'sodium@sodium.com', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '13', title: 'Aluminum', body: 'sodium@sodium.com', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
  { _id: '14', title: 'Silicon', body: 'sodium@sodium.com', price: 10, imgFrontPage: 'ROLE_USER', imgGallery: ['image.jpg', 'image2.jpeg'], category: 'Categoria', location: 'Location', duration: 120, date: 'Date' },
];

const news: News[] = [
  { _id: '1', title: 'hyd', imgFrontPage: 'Hydrogen', body: 'ROLE_USER' },
  { _id: '2', title: 'helium', imgFrontPage: 'Helium', body: 'ROLE_USER' },
  { _id: '3', title: 'lithium', imgFrontPage: 'Lithium', body: 'ROLE_USER' },
  { _id: '4', title: 'beryllium', imgFrontPage: 'Beryllium', body: 'ROLE_USER' },
  { _id: '5', title: 'boron', imgFrontPage: 'Boron', body: 'ROLE_USER' },
  { _id: '6', title: 'Carbon', imgFrontPage: 'carbon', body: 'ROLE_USER' },
  { _id: '7', title: 'Nitrogen', imgFrontPage: 'nitrogen', body: 'ROLE_USER' },
  { _id: '8', title: 'Oxygen', imgFrontPage: 'oxygen', body: 'ROLE_USER' },
  { _id: '9', title: 'Fluorine', imgFrontPage: 'fluorine', body: 'ROLE_USER' },
  { _id: '10', title: 'Neon', imgFrontPage: 'neon@neon.com', body: 'ROLE_USER' },
  { _id: '11', title: 'Sodium', imgFrontPage: 'sodium@sodium.com', body: 'ROLE_USER' },
  { _id: '12', title: 'Magnesium', imgFrontPage: 'sodium@sodium.com', body: 'ROLE_USER' },
  { _id: '13', title: 'Aluminum', imgFrontPage: 'sodium@sodium.com', body: 'ROLE_USER' },
  { _id: '14', title: 'Silicon', imgFrontPage: 'sodium@sodium.com', body: 'ROLE_USER' },
];


@Injectable()
export class TablesService {

  constructor() { }

  getData() {
    return data;
  }

  getAdmins() {
    return admins;
  }

  getFormations() {
    return formations;
  }

  getNews() {
    return news;
  }
}

export interface Admin {
  _id: string;
  username: string;
  email: string;
  role: string;
}

export interface Formation {
  _id: string;
  title: string;
  body: string;
  price: number;
  imgFrontPage: string;
  imgGallery: Array<string>;
  category: string;
  duration: number;
  location: string;
  date: string,
}

export interface News {
  _id: string;
  title: string;
  body: string;
  imgFrontPage: string;
}