import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { set } from 'date-fns';
import { Admin } from 'src/app/pages/tables/tables.service';
import { AdminService } from 'src/app/shared/services/admin.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/img/users/default-user.jpg";
  public admin: Admin;
  email: string;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute, 
    private router: Router,
    private jwtService: JwtHelperService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      let decode = this.jwtService.decodeToken(localStorage.getItem('token'));
      console.log('decode', decode);
      this.email = decode.email;
    }, 1000);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

}
