import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AdminsComponent } from './pages/admins/admins.component';
import { NewAdminsComponent } from './pages/new-admins/new-admins.component';
import { NewUsersComponent } from './pages/new-users/new-users.component';

import { AuthGuardService as AuthGuard } from 'src/app/auth/auth-guard.service';
import { UsersValidateComponent } from './pages/users-validate/users-validate.component';
import { AdvertisementsComponent } from './pages/advertisements/advertisements.component';
import { ProfecionityComponent } from './pages/profecionity/profecionity.component';
import { NewProfecionityComponent } from './pages/new-profecionity/new-profecionity.component';
import { EditProfecionityComponent } from './pages/edit-profecionity/edit-profecionity.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, canActivate: [AuthGuard], children: [
            { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },
            { path: 'mailbox', loadChildren: () => import('./pages/mailbox/mailbox.module').then(m => m.MailboxModule), data: { breadcrumb: 'Mailbox' } },
            { path: 'forum', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Forum' } },
            { path: 'form-controls', loadChildren: () => import('./pages/form-controls/form-controls.module').then(m => m.FormControlsModule), data: { breadcrumb: 'Form Controls' } },
            { path: 'tables', loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Tables' } },
            { path: 'icons', loadChildren: () => import('./pages/icons/icons.module').then(m => m.IconsModule), data: { breadcrumb: 'Material Icons' } },
            { path: 'profile', loadChildren: () => import ('./pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } },
            { path: 'ads', loadChildren: () => import ('./pages/ads/ads.module').then(m => m.AdsModule), data: { breadcrumb: 'Ads' } },
            { path: 'change-ads-image/:adsUuid/:image', loadChildren: () => import ('./pages/change-ads-image/change-ads-image.module').then(m => m.ChangeAdsImageModule), data: { breadcrumb: 'Cambiar imagen' } },
            { path: 'new-ads', loadChildren: () => import ('./pages/new-ads/new-ads.module').then(m => m.NewAdsModule), data: { breadcrumb: 'Nuevo Anuncio' } },
            { path: 'news', loadChildren: () => import ('./pages/news/news.module').then(m => m.NewsModule), data: { breadcrumb: 'Noticias' } },
            { path: 'new-news', loadChildren: () => import ('./pages/new-news/new-news.module').then(m => m.NewNewsModule), data: { breadcrumb: 'Nueva Noticia' } },
            { path: 'formations', loadChildren: () => import ('./pages/formations/formations.module').then(m => m.FormationsModule), data: { breadcrumb: 'Formaciones' } },
            { path: 'new-formations', loadChildren: () => import ('./pages/new-formations/new-formations.module').then(m => m.NewFormationsModule), data: { breadcrumb: 'Nueva Formación' } },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'admins', component: AdminsComponent, data: {'breadcrumb': 'Administradores'} },
            { path: 'users-validate', component: UsersValidateComponent, data: {'breadcrumb': 'Usuarios para validar'} },
            { path: 'new-admins', component: NewAdminsComponent, data: {'breadcrumb': 'Nuevo admin'} },
            { path: 'new-users', component: NewUsersComponent, data: {'breadcrumb': 'Nuevo usuario'} },
            { path: 'edit-user/:uuid', component: EditUserComponent, data: {'breadcrumb': 'Editar Usuario'} },
            { path: 'advertisements', component: AdvertisementsComponent, data: {'breadcrumb': 'Anuncios'} },
            { path: 'publications', loadChildren: () => import ('./pages/publications/publications.module').then(m => m.PublicationsModule), data: {'breadcrumb': 'Publicaciones'} },
            { path: 'forum/threads/:uuid', loadChildren: () => import ('./pages/thread/thread.module').then(m => m.ThreadModule), data: {'breadcrumb': 'Foros'} },
            { path: 'new-advertisements', loadChildren: () => import ('./pages/new-advertisements/new-advertisements.module').then(m => m.NewAdvertisementsModule), data: { breadcrumb: 'Nuevo Anuncio' } },
            { path: 'edit-ads/:adsUuid/:adNumber', loadChildren: () => import ('./pages/edit-ads/edit-ads.module').then(m => m.EditAdsModule), data: { breadcrumb: 'Editar anuncio' } },
            { path: 'new-publications', loadChildren: () => import ('./pages/new-publications/new-publications.module').then(m => m.NewPublicationsModule), data: { breadcrumb: 'Nueva Publicación' } },
            { path: 'specialty', component: ProfecionityComponent, data: {'breadcrumb': 'Profesiones'} },
            { path: 'new-specialty', component: NewProfecionityComponent, data: {'breadcrumb': 'Nueva Especialidad'} },
            { path: 'edit-specialty/:uuid', component: EditProfecionityComponent, data: {'breadcrumb': 'Edit Especialidad'} },
        ]
    },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
