import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SpecialityService } from 'src/app/shared/services/speciality.service';

@Component({
  selector: 'app-profecionity',
  templateUrl: './profecionity.component.html',
  styleUrls: ['./profecionity.component.scss']
})
export class ProfecionityComponent implements OnInit {

  public displayedColumns = ['name', 'createdAt', 'actions'];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(
    private readonly specialityService: SpecialityService,
    private readonly snackBar: MatSnackBar,
    private router: Router,) { }
  dataSource: Speciality[]

  ngOnInit(): void {
    this.specialityService.list().subscribe({
      next: data => {
        this.dataSource = data?.data as Speciality[]
      },
      error: () => alert('Ha ocurrido un error')
    })
  }

  handleDelete(id: string) {
    this.specialityService.delete(id).subscribe({
      next: data => {
        this.snackBar.open(data?.message, '', { 'duration': 5000 })
        this.specialityService.list().subscribe({
          next: data => {
            this.dataSource = data?.data as Speciality[]
          },
          error: () => alert('Ha ocurrido un error')
        })
      },
      error: () => {
        this.snackBar.open('Ha ocurrido un error y su profecion no pudo eliminarse', '', { 'duration': 5000 })
      }
    })
  }

  editData(id: string) {
    this.router.navigate(['/edit-specialty', id]);
  }

}

interface Speciality {
  _id: string;
  name: string
}
