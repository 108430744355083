import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
moment.locale('es')

@Pipe({
  name: 'momentToNow'
})
export class momentToNowPipe implements PipeTransform {

  transform(fecha: string | Date): string {
    if(typeof fecha === 'string') fecha = new Date(fecha)
    return moment(fecha).fromNow()
  }

}