import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Sweetalert2Service } from 'src/app/shared/services/sweetalert2.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-dowload-users-btn',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dowload-users-btn.component.html',
  styleUrls: ['./dowload-users-btn.component.scss']
})

export default class DowloadUsersBtnComponent {
  public fileS = inject(FileUploadService)
  public fwe = inject(Sweetalert2Service)
  public btnDisabled = false
  updateCSV(){
    this.btnDisabled = true
    this.fileS.updateUserFile().subscribe({
      next:(a)=>{
        this.fwe.fireSweetOkButton('Petición en proceso','Espere un momento, el servidor esta actualizando el archivo','info','Gracias, esperaré')
        setTimeout(() => { this.btnDisabled = false }, 60000);
      },error:(e)=>{
        this.btnDisabled = false
        this.fwe.fireSweetError(e)
      }
    })
  }
  
  donwload(){
    this.fileS.downloadUserFile().subscribe({
      next:(response)=>{
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'usuarios.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      },error:(e)=>{
        this.fwe.fireSweetError(e)
      }
    })
  }
}
